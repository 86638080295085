<template>
  <v-dialog v-model="dialog" max-width="1200px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <div class="edit" v-bind="attrs" v-on="on">
        <v-icon color="#0033A0">mdi-note-edit-outline</v-icon>
      </div>
    </template>
    <v-card>
      <v-card-text>
        <div class="list">
          <table>
            <thead>
              <tr>
                <th>Параметр</th>
                <th></th>
                <th>
<!--                  <a href="#">Шаблон для импорта конфигурации.csv</a>-->
                  <v-btn outlined @click="$refs['fileInput'].click()" class="mt-4">загрузить файл CSV</v-btn>
                  <input ref="fileInput" style="display: none" type="file" @change="loadCSV">
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(parameter, parameterIndex) in configParams" :key="'index'+parameterIndex+parameterKey">
                <td style="vertical-align: top;">{{ parameterIndex + 1 }}. {{ parameter.name }}</td>
                <td style="width: 150px">
                  <div style="color: #CF4520">
                    {{ parameter.isFileNeeded ? 'Прикрепление файла обязательно' : '' }}
                  </div>
                  <div>
                    Отчётная дата:<br> {{ parameter.recommendedEndDate | dateFormat }}
                  </div>
                  <div>
                    Дата изменения:<br> {{ values.modifiedDateValues[parameter.parameterName] | dateFormat }}
                  </div>
                </td>
                <td style="width: 650px">
                  <div class="d-flex align-center">
                    <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
                      <path d="M8.94029 5.78581C8.59471 5.78581 8.31597 6.05336 8.31597 6.39919V15.4626C8.31597 16.388 7.98158 17.146 7.33488 17.7927C6.68819 18.4394 5.91905 18.7738 4.98271 18.7738C4.06847 18.7738 3.28814 18.4505 2.64147 17.8039C1.99481 17.1572 1.67158 16.3769 1.67158 15.4626V12.0402V10.2119V5.7078V3.54518V3.5228V3.50045C1.67158 2.88732 1.89728 2.35505 2.32943 1.91742C2.76705 1.47434 3.29908 1.23745 3.93481 1.23745C4.57031 1.23745 5.10259 1.47434 5.53993 1.91742C5.97208 2.35505 6.19781 2.88732 6.19781 3.50045V3.5228V3.5673V13.6791C6.19781 14.3143 5.70738 14.805 5.0719 14.805C4.45875 14.805 3.95693 14.2922 3.95693 13.6791V8.38343C3.95693 8.03785 3.68935 7.7703 3.3438 7.7703C2.99824 7.7703 2.73067 8.03785 2.73067 8.38343V13.6791C2.73067 14.3367 2.95375 14.8942 3.41064 15.3513C3.86776 15.8084 4.41408 16.0313 5.07193 16.0313C5.72953 16.0313 6.28152 15.8027 6.74413 15.3513C7.19579 14.9106 7.42413 14.3591 7.42413 13.6791V3.5228V3.50045C7.42413 2.54174 7.07569 1.71955 6.39857 1.03672C5.71572 0.348182 4.89352 0 3.93481 0C2.97587 0 2.15367 0.348182 1.47084 1.03672C0.793747 1.71955 0.445312 2.54174 0.445312 3.50045V3.5228V5.7078V10.2119V12.0402V15.4626C0.445312 16.7224 0.891247 17.7927 1.77195 18.6733C2.65266 19.554 3.72289 20 4.98271 20C6.24254 20 7.33488 19.5764 8.22678 18.6845C9.11865 17.7927 9.55342 16.7003 9.55342 15.4626V6.39919C9.55342 6.05336 9.28584 5.78581 8.94029 5.78581Z" fill="#777779"/>
                    </svg>
                    <template v-if="!values.fileValues[parameter.parameterName]">
                      <input type="file" :ref="parameter.parameterName" style="display: none"
                             @change="uploadFile(indexId, parameter.parameterName)">
                      <a @click="$refs[parameter.parameterName][0].click()"  class="upload-link"><i>Загрузить</i></a>
                    </template>
                    <template v-else>
                      <a @click="downloadFile(indexId, parameter.parameterName)">
                        {{ truncateStr(getFileName(values.fileValues[parameter.parameterName]), 50) }}
                      </a>
                      <a @click="deleteFile(indexId, parameter.parameterName)">
                        <v-icon>mdi-window-close</v-icon>
                      </a>
                    </template>
                  </div>
                  <template v-if="parameter.type === 'STRING'">
                    <v-textarea
                        placeholder="Комментарий"
                        v-model="values.textValues[parameter.parameterName]"
                        @change="validateValue(values.textValues[parameter.parameterName],
                                               parameter.type,
                                               parameterIndex); parameterKey+=1"
                    ></v-textarea>
                    <span v-if="errorText[parameterIndex]" class="validate-error">{{ errorText[parameterIndex] }}</span>
                  </template>
                  <template v-if="parameter.type === 'NUMBER'">
                    <v-text-field
                        placeholder="Числовое значение"
                        v-model="values.textValues[parameter.parameterName]"
                        @change="validateValue(values.textValues[parameter.parameterName],
                                               parameter.type,
                                               parameterIndex); parameterKey+=1"
                    ></v-text-field>
                    <span v-if="errorText[parameterIndex]" class="validate-error">{{ errorText[parameterIndex] }}</span>
                  </template>
                  <template v-if="parameter.type === 'DATE'">
                    <div style="width: 300px">
                      <DateField
                          labelName="Дата"
                          :dateValue="values.textValues[parameter.parameterName]"
                          @minput="values.textValues[parameter.parameterName]=$event; parameterKey+=1"
                      ></DateField>
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
       </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            class="btn btn-outline"
            text
            @click="$emit('updated'); dialog = false"
        >
          Отмена
        </v-btn>
        <v-btn
           class="btn btn-primary"
           :disabled="submitButtonDisabled || !isValidValuesList()"
           @click="submit()"
        >
          Сохранить изменения
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/modules/api";
import DateField from "@/components/elements/DateField";

export default {
  name: 'StandardEditDialog',
  props: ['configParams', 'indexId', 'values'],
  // props: ['configParams', 'indexId', 'textValues', 'fileValues', 'modifiedDateValues'],
  components: {DateField},

  data: () => ({
    dialog: false,
    comment: null,
    apiLoaded: true,
    submitButtonDisabled: false,
    valueCSV: null,
    parameterKey: 0,
    errorText: [],
  }),

  methods: {
    //кнопка "Сохнанить изменения"
    async submit() {
      let textValues = new Map(),
        // formDataId: Number (this.formDataId),
        // fieldName: this.configParams[0].parameterName
          validStat = true //this.validateConfig(this.config);
      if (validStat === true) {
        textValues = this.formTextValues();
        // console.log('textValues submit', textValues);
        await this.saveData(textValues, "/monitoring/typical/index/setTextParameters");
      } else {
        this.errorText = validStat
      }

      this.$emit('updated');
    },

    async saveData(obj, path) {
      this.submitButtonDisabled = true
      let req = await api.postJson(path, obj, {indexId: this.indexId})
      // console.log ('req', req)
      if (req.ok) {
        this.dialog = false;
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
      this.submitButtonDisabled = false
    },

    formTextValues() {
      let map = new Map();
      for (let parameter of this.configParams) {
        // console.log('this.textValues[parameter.parameterName]', this.values.textValues[parameter.parameterName])
        map.set(parameter.parameterName, this.values.textValues[parameter.parameterName])
      }
      return Object.fromEntries(map)
    },

    async uploadFile(indexId, parameterName) {
      let file = this.$refs[parameterName][0].files[0];
      if (file.size > 52428800) {
        alert('Файл больше 50Мб. Загрузите, пожалуйста, файл с меньшим размером');
        this.$refs[parameterName][0].value = "";
        return
      }
      // console.log('file', file);
      let formData = new FormData();
      formData.append('file', file);

      let req = await api.postFile(
          '/monitoring/typical/index/setFileParameter',
          {
            indexId: indexId,
            parameterName: parameterName,
          },
          formData,
          'POST'
      );
      // console.log('req', req)
      if (req.ok) {
        console.log('upload ok');
        this.values.fileValues[parameterName] = req.payload.parametersFilePart[parameterName];
        this.values.modifiedDateValues[parameterName] = req.payload.modifiedDate[parameterName];
        this.$forceUpdate();
      } else {
        console.log('upload error', req.error);
      }
    },

    async downloadFile(indexId, parameterName) {
      let req = await api.download("/monitoring/typical/index/getFile", {
        indexId: indexId,
        parameterName: parameterName,
      });
      // console.log('req', req)
      if (req.ok) {
        let link = document.createElement('a');
        link.href = URL.createObjectURL(req.payload);
        link.download = this.getFileName(this.values.fileValues[parameterName]);
        link.click();
        URL.revokeObjectURL(link.href);
      } else {
        console.log('download error');
      }
    },

    async deleteFile(indexId, parameterName) {
      let req = await api.delete("/monitoring/typical/index/deleteFileParameter", {
        indexId: indexId,
        parameterName: parameterName,
      });
      // console.log('req', req)
      if (req.ok) {
        console.log('delete ok');
        this.values.fileValues[parameterName] = "";
        this.$forceUpdate();
      } else {
        console.log('delete error');
      }
    },

    getFileName(href) {
      return href.substring(href.lastIndexOf("/") + 1);
    },

    isValidValuesList(){
      for (let error of this.errorText){
        if (error != null)
          return false
      }
      return true
    },

    validateValue(value, type, index) {
      let valStat = this.isValid(value, type)
      if (valStat === true) {
        this.errorText[index] = null
      } else {
        this.errorText[index] = valStat
      }
    },

    isValid(value, type) {
      if(value == null || value === "")
        return true
      if (type === 'NUMBER') {
        if (!this.isNumeric(value)) {
          return "Некорректное число"
        }
      // } else if (type === 'INTEGER') {
      //   if (!this.isNormalInteger(value)) {
      //     return "Некорректное целое число"
      //   }
      } else if (type === 'STRING') {
        if (value.length > 4000) {
          return "Длина текста не может превышать 4000 символов"
        }
      }
      return true
    },

    // isNormalInteger(str) {
    //   var n = Math.floor(Number(str));
    //   return n !== Infinity && String(n) === str && n >= 0;
    // },

    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      let replacedCommaStr = Number(str.replace(/,/, '.')); //замена запятой на точку для проверки
      return !isNaN(replacedCommaStr) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(replacedCommaStr)) // ...and ensure strings of whitespace fail
    },

    loadCSV() {
      let file = this.$refs['fileInput'].files[0];
      let reader = new FileReader();
      reader.readAsText(file);
      reader.componentThis = this;
      reader.onload = function () {
        // console.log('reader.result', reader.result)
        let csv = reader.result
        reader.componentThis.getValueCSV(csv.toString())
      };
      this.$refs['fileInput'].value = ""
      reader.onerror = function () {
        alert('Ошибка загрузки файла')
      };
    },

    getValueCSV(valueCSV) {
      let textArr = valueCSV.split('\r\n');
      for (let i = 0; i < this.configParams.length; i++) {
        this.values.textValues[this.configParams[i].parameterName] = textArr[i];
      }
      this.$forceUpdate();
    },

    truncateStr(str, n) {
      if (str.length > (n + 6)) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },
  },

  filters: {
    dateFormat: function (date) {
      if (date == null || date === "") {
        return '—'
      }
      let mdate = new Date(date)
      let options = {timeZone: 'Europe/Moscow', year: 'numeric', month: 'numeric', day: 'numeric'}
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
}
</script>

<style lang="scss" scoped>
  table {
    border-collapse: collapse;
    th {
      border-bottom: 1px solid #E8E8E9;
      &:last-child {
        text-align: right;
        a {
          text-decoration: underline;
        }
        .v-btn {
          margin-left: 27px;          
        }
      }
    }
    td {
      &:nth-child(2) {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: right;
        vertical-align: top;
        //span {
        //  color: #FC5252;
        //}
        div {
          color: #777779;
          margin-bottom: 8px;
        }
      }
      &:last-child {
        vertical-align: top;
      }
    }
    .v-textarea textarea {
      height: 60px !important;
      padding: 10px !important;
    }
  }
.v-dialog .v-card__actions .btn {
width: auto !important;
}

.validate-error {
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  font-size: 12px;
  color: red;
}
</style>

